.app_layout {
    height: 100vh;
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        .left {
            display: flex;
            align-items: center;
            .logo {
                box-sizing: border-box;
                float: left;
                width: 200px;
                height: 31px;
                background: rgba(255, 255, 255, 0.3);
                &.collapsed {
                    width: 50px;
                }
            }
            .collapsed-icon {
                margin-left: 10px;
                color: #1890ff;
                font-size: 20px;
                cursor: pointer;
                user-select: none;
            }
        }
        .right {
            margin-right: 10px;
            user-select: none;
        }

    }


    .ant-row-rtl #components-layout-demo-top-side-2 .logo {
        float: right;
        margin: 16px 0 16px 24px;
    }
      
    .site-layout-background {
        background: #fff;
    }

    .site-page-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        .ant-layout-content {
            background: #fff;
            // flex: 1;
            flex: unset;
            overflow: auto;
        }
    }

    .ant-layout.ant-layout-has-sider {
        flex: 1;
        height: 100%;
        .ant-layout {
            height: 100%;
        }
    }
}

p.popver {
    margin: 0;
    button {
        padding-left: 0;
    }
}


