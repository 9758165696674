ul.tree {
    // border: 1px solid #808080;
    // padding: 20px;
    padding-left: 0;
    li.tree-item {
        list-style: none;
    }
    h3 {
        padding-left: 20px;
        margin: 0;
        height: 64px;
        border: 1px solid #dedede;
        line-height: 64px;
        color: #222;
        box-sizing: border-box;
        margin-top: -1px;
        span {
            margin-left: 10px;
        }
    }
    h3 + ul {
        padding-left: 50px;
    }
}

.sort-content {
    padding-top: 20px;
}